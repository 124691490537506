import { Auth } from "@aws-amplify/auth";

/**
 * These values will become dynamic per each env once the ErgonaCDK is complete.
 */
const REGION = "us-east-1";
const IDENTITY_POOL_ID = "us-east-1:4fd8b168-1629-4fba-8131-75a1af4d5962";
const USER_POOL_ID = "us-east-1_vIxqfBamM";
const WEB_CLIENT_ID = "75m7n04onf4g302s3vubkh81o6";
const DOMAIN = `allegro-beta-na.auth.us-east-1.amazoncognito.com`;

export async function Init() {
  const config = {
    stage: "beta",
    aws_cognito_region: REGION,
    aws_cognito_identity_pool_id: IDENTITY_POOL_ID,
    aws_user_pools_id: USER_POOL_ID,
    aws_user_pools_web_client_id: WEB_CLIENT_ID,
    Auth: {
      region: REGION,
      identityPoolId: IDENTITY_POOL_ID,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: WEB_CLIENT_ID,
    },
    oauth: {
      domain: DOMAIN,
      redirectSignIn: "https://beta.na.headphones.whs.amazon.dev",
      redirectSignOut: "https://beta.na.headphones.whs.amazon.dev",
      scope: ["openid", "profile", "email"],
      responseType: "code",
    },
  };
  Auth.configure(config);
}
